<template>
  <div id="app" v-if="isReload">
    <navTop></navTop>
    <div class="main">
      <router-view :key="$route.fullPath" />
      <div class="useBtnList">
        <div>
          <button onclick="qimoChatClick();" class="consultBtn"
            :style="`background-image: url('${$ImgUrl}/public/kefu.png');border-radius:50%`"> </button>
        </div>
        <div>
          <button @click="callMe('4006683398')" class="consultBtn"
            :style="`background-image: url('${$ImgUrl}/public/phone.png');border-radius:50%`"> </button>
        </div>
      </div>
    </div>
    <footBottom></footBottom>
  </div>
</template>
<script>
import navTop from "./components/navTop.vue";
import footBottom from "./components/footBottom.vue";
import { publicizeUrl } from '@/config/env'
export default {
  components: { navTop, footBottom },
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isReload: true
    }
  },
  created() {
    //判断是否是h5 如果是 跳转宣传页
    let params = this.getUrlParams(location.href)
    console.log(params)
    if (params.comeFrom && params.comeFrom == 'leaflets') {
    } else {
      this.isH5()
    }

  },
  beforeDestroy() {

  },
  methods: {
    getUrlParams(url) {
      // 创建空对象存储参数
      let obj = {};
      if (url.indexOf('?') != -1) {
        // 通过 ? 分割获取后面的参数字符串
        let urlStr = url.split('?')[1]
        // 再通过 & 将每一个参数单独分割出来
        let paramsArr = urlStr.split('&')
        for (let i = 0, len = paramsArr.length; i < len; i++) {
          // 再通过 = 将每一个参数分割为 key:value 的形式
          let arr = paramsArr[i].split('=')
          obj[arr[0]] = arr[1];
        }
      }
      return obj

    },
    // 拨打电话
    callMe(phoneNumber) {
      window.location.href = 'tel://' + phoneNumber
    },
    isH5() {
      if (!sessionStorage.getItem('isGO')) {
        let client =
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          );
        if (client) {
          sessionStorage.setItem('isGO', true)
          location.replace(publicizeUrl)
        }
      }
    },
    reload() {
      this.isReload = false
      const loading = this.$loading({
        lock: true,
        text: '正在切换语言',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      setTimeout(() => {
        loading.close();
        this.$nextTick(() => {
          this.isReload = true
        })
      }, 2000);

    },
  }
}
</script>
<style lang="scss">
#app {
  // position: relative;
  overflow: hidden;
}

.main {
  padding-top: 80px;
  box-sizing: border-box;
}

.useBtnList {
  position: fixed;
  right: 20px;
  top: 50%;
  z-index: 1000;

  .consultBtn {
    border: none;
    background-repeat: no-repeat;
    background-size: 60% 60%;
    background-position: center center;
    width: 40px;
    height: 40px;
  }

  .gnInfo {
    cursor: pointer;
  }
}

h6 {
  font-weight: normal !important;
  line-height: 1.7 !important;

}

// col-sm-
@media (min-width: 576px) and (max-width: 768px) {
  h2 {
    font-size: 1.25rem !important;
  }

  h3 {
    font-size: 1.1rem !important;

  }

  h4 {
    font-size: 1rem;
  }

  h5 {
    font-size: 1rem !important;

  }

  h6 {
    font-size: 0.75rem !important;

  }
}

// col-
@media(max-width: 576px) {
  h2 {
    font-size: 1.25rem !important;
  }

  h3 {
    font-size: 1.1rem !important;

  }

  h4 {
    font-size: 1rem !important;
  }

  h5 {
    font-size: 0.9rem !important;

  }

  h6 {
    font-size: 0.75rem !important;

  }
}
</style>