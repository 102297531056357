<!-- 全局顶部导航栏 -->
<template>
  <div @mouseleave="showTwoRoute = false">
    <nav class="big-nav topnav navbar navbar-expand-lg navbar-light fixed-top bg-color">
      <div class="container viewRow viewbetween">
        <img class="logo" :src="`${$ImgUrl}/public/logo.png`" alt="">
        <button class="navbar-toggler collapsed" type="button" @click="show = true">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse collapse" id="navbarColor02" style="">
          <div class="nav-big">
            <ul :class="['nav-box navbar-nav', language == 'en' ? '' : 'viewAround']">
              <div class="viewRow viewCenter" v-for="(item, index) in routerList" :key="index">
                <li @click="goPage(item, true, index, $event)"
                  :class="['nav-item viewCol viewStart ', { navActive: item.isCurrent }, language == 'en' ? '' : 'pl-2']"
                  v-if="!item.children || !item.children.length != 0">
                  <div class="nav-link">{{ $t(`${item.meta.languageValue}`) }}</div>
                  <div class="navLine"></div>
                </li>
                <li @click="goPage(item, true, index, $event)" @mouseover="twoIndex = index; showTwoRoute = true"
                  :class="['nav-item viewCol viewStart', { navActive: item.isCurrent }]" v-else>
                  <div class="nav-link">
                    {{ $t(`${item.meta.languageValue} `) }} </div>
                  <div class="navLine"></div>
                </li>
              </div>
            </ul>
          </div>
          <ul class="navbar-nav viewRow viewStart navRight">
            <!-- <li class="nav-item viewRow viewStart">
              <i class="bi bi-telephone-fill"></i>
              <span class="phone-num">400-666-3398</span>
            </li>
            <div class="line">|</div> -->
            <div class="dropdown">
              <button type="button" class="btn dropdown-toggle" id="dropdownMenu1" data-toggle="dropdown">{{
                languageName }}
                <span class="caret"></span>
              </button>
              <ul class="dropdown-menu" role="menu" aria-labelledby="dropdownMenu1" style="width: 100%;">
                <li role="presentation" @click=" languageEvt(1, 'cn')">
                  {{ $t('nav.info.zhLang') }}
                </li>
                <li role="presentation" @click=" languageEvt(2, 'en')">
                  {{ $t('nav.info.enLang') }}
                </li>
              </ul>
            </div>
            <button class="btn-box" @click="goAdmin"> {{ $t('nav.info.adminText') }}</button>
          </ul>
        </div>
      </div>
    </nav>
    <!-- @mouseleave="reset()" -->
    <div v-if="showTwoRoute" class="container-full dialog-box viewRow viewStartStart p-0"
      @mouseenter=" showTwoRoute = true" @mouseleave=" showTwoRoute = false">
      <div class="dialog-left col-xl-3 p-0">
        <ul class="list-group">
          <li @mouseover=" mouseoverTwo(index)" v-for="(item, index) in routerList[twoIndex].children" :key="index"
            @click=" goPage(item)" :class="['list-group-item', 'item-1', item.isCurrent ? 'text-active' : '']">{{
              $t(`${item.meta.languageValue} `)
            }}
            &#62
          </li>
        </ul>
      </div>
      <div class="dialog-right viewRow viewStart col-xl-9 p-0">
        <ul class="list-group">
          <li @mouseover=" img = item.meta.img" @click=" goPage(item)"
            v-for="(item, index) in routerList[twoIndex].children[threeIndex].children" :key="index"
            :class="['list-group-item', 'item-2', item.isCurrent ? 'text-active' : '']">{{
              $t(`${item.meta.languageValue} `) }}
          </li>
        </ul>
        <div v-show="showImg()" class="nav-img-box viewCol viewCenter">
          <img :src="showImg()" alt="">
        </div>
      </div>
    </div>
    <el-drawer :visible.sync="show" direction="ltr" size="80%">
      <el-menu router class="el-menu-vertical-demo" @select="selectMenu">
        <div v-for="(item, index) in routerList" :key="index">
          <el-submenu :index="item.path" v-if="item.children && item.children.length != 0">
            <template slot="title">{{ $t(`${item.meta.languageValue} `) }}</template>
            <div v-for="(itm, idx) in item.children" :key="idx">
              <el-submenu :index="itm.path" v-if="itm.children && itm.children.length != 0">
                <template slot="title">{{ $t(`${itm.meta.languageValue} `) }}</template>
                <el-menu-item v-for="(it, ix) in itm.children" :key="ix" :index="it.path">{{
                  $t(`${it.meta.languageValue
                    } `) }}</el-menu-item>
              </el-submenu>
              <el-menu-item v-else :index="itm.path">{{ $t(`${itm.meta.languageValue} `) }}</el-menu-item>
            </div>
          </el-submenu>
          <el-menu-item v-else :index="item.path">{{ $t(`${item.meta.languageValue} `) }}</el-menu-item>
        </div>
      </el-menu>
      <el-select style="width: 80%;" v-model="languageName" @change="changeSelct" placeholder="请选择">
        <el-option :key="1" :label="$t('nav.info.zhLang')" value="中">
        </el-option>
        <el-option :key="2" :label="$t('nav.info.enLang')" value="英">
        </el-option>
      </el-select>

    </el-drawer>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { adminUrl } from '@/config/env'
export default {
  inject: ['reload'],
  data() {
    return {
      languageName: this.$t('nav.info.zhLang'),
      oneIndex: 0,
      twoIndex: 0,
      threeIndex: 0,
      showTwoRoute: false,
      show: false,
      img: "",
    };
  },
  created() {
  },
  mounted() {
    this.languageName = this.language == "en" ? this.$t('nav.info.enLang') : this.$t('nav.info.zhLang');
    // this.updataActiveNav()
  },
  beforeDestroy() { },
  computed: {
    ...mapGetters(['language']),
    routerList() {
      let routerList = JSON.parse(
        JSON.stringify(this.$store.getters.routerList)
      );
      if (this.$route.meta.navGroup) {
        let navGroupList = this.$route.meta.navGroup.split("-");
        routerList = this.setRouter(routerList, navGroupList);
      }

      return routerList;
    },
  },
  methods: {
    ...mapMutations(['setLangeStyle']),
    selectMenu(index, indexPath) {
      console.log(index, indexPath)
      this.show = false
    },
    // 初始化页面导航栏位置
    // updataActiveNav(){

    // },
    goAdmin() {
      if (adminUrl) {
        location.href = adminUrl
      }
    },
    mouseoverTwo(index) {
      if (index != this.threeIndex) {
        this.threeIndex = index;
        this.img = "";
      }
    },
    showImg() {
      if (this.img) {
        return this.$ImgUrl + this.img;
      }
      for (let i in this.routerList[this.twoIndex].children[this.threeIndex]
        .children) {
        let item =
          this.routerList[this.twoIndex].children[this.threeIndex].children[i];
        if (item.isCurrent) {
          return this.$ImgUrl + item.meta.img;
        }
      }
      return false;
    },
    reset() {
      // this.img = "";
      // this.threeIndex = 0;
      this.showTwoRoute = false;
    },
    // 每个级别路由常亮
    setRouter(list, navGroupList) {
      list.map((item) => {
        let itemNavGroup = item.meta.navGroup.split("-");
        //哪级菜单
        let levegrade = this.getLevegrade(item.meta.navGroup);
        if (levegrade == 1) {
          if (itemNavGroup[0] == navGroupList[0]) {
            item.isCurrent = true;
          } else {
            item.isCurrent = false;
          }
        } else if (levegrade == 2) {
          if (
            itemNavGroup[0] == navGroupList[0] &&
            itemNavGroup[1] == navGroupList[1]
          ) {
            item.isCurrent = true;
          } else {
            item.isCurrent = false;
          }
        } else {
          if (
            itemNavGroup[0] == navGroupList[0] &&
            itemNavGroup[1] == navGroupList[1] &&
            itemNavGroup[2] == navGroupList[2]
          ) {
            item.isCurrent = true;
          } else {
            item.isCurrent = false;
          }
        }
        if (item.children && item.children.length != 0) {
          item.children = this.setRouter(item.children, navGroupList);
        }
      });
      return list;
    },
    getLevegrade(navGroup) {
      let itemNavGroup = navGroup.split("-");
      let levegrade;
      if (itemNavGroup[1] == 0) {
        levegrade = 1;
      } else if (itemNavGroup[2] == 0) {
        levegrade = 2;
      } else {
        levegrade = 3;
      }
      return levegrade;
    },
    goPage(item, isOne = false, id, event) {
      if (item.path && this.$route.meta.navGroup != item.meta.navGroup) {
        this.$router.push(item.path);
        this.reset()
        if (isOne) {
          this.changeTab(id, event)
        }
      }
    },
    changeTab(id, event) {
      // 如果选择的和当前激活的不同
      if (id !== this.oneIndex) {
        this.oneIndex = id;
        // 计算当前按钮的位置，看是否需要移动

        const spanLeft = event.clientX; // 当前点击的元素左边距离
        const divBox = document.querySelector(".nav-item").clientWidth / 2; // 点击的元素一半宽度
        const totalWidths = document.body.clientWidth; // 屏幕总宽度
        const widths = totalWidths / 2; // 一半的屏幕宽度
        const spanRight = totalWidths - spanLeft; // 元素的右边距离
        const scrollBox = document.querySelector(".nav-box"); // 获取最外层的元素
        const scrollL = scrollBox.scrollLeft; // 滚动条滚动的距离
        // 当元素左边距离 或者 右边距离小于100时进行滑动
        if (spanLeft < 900 || spanRight < 1000) {
          scrollBox.scrollLeft = scrollL + (spanLeft - widths) + divBox;
        }
      }
    },
    changeSelct(e) {
      let val = e == "中" ? 1 : 2;
      let lange = e == "中" ? "cn" : "en";
      this.languageEvt(val, lange);
    },
    // 切换为中文
    languageEvt(val, lange = "cn") {
      if (this.language == lange) {
        return
      }
      this.$i18n.locale = lange;
      switch (val) {
        case 1:
          this.languageName = this.$t('nav.info.zhLang');
          this.setLangeStyle(lange)
          // localStorage.setItem("lang", lange);
          break;
        case 2:
          this.languageName = this.$t('nav.info.enLang');
          this.setLangeStyle(lange)

          // localStorage.setItem("lang", lange);
          break;
        default:
          this.languageName = this.$t('nav.info.zhLang');
          this.setLangeStyle(lange)
          // localStorage.setItem("lang", lange);
          break;
      }

      this.reload()
    },
  },
};
</script>
<style lang="scss">
.list-group {
  cursor: pointer;

  .item-1 {
    text-align: right;
  }

  .item-2 {}
}

.list-group {
  .list-group-item:hover {
    color: #e58419 !important;
  }
}

.dialog-box {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  z-index: 1030;
  background: #fff;

  .text-active {
    color: #e58419 !important;
  }

  .dialog-left {
    // overflow-y: scroll;
    // flex: 1;
    background: #f6f6f6;

    .list-group {
      background: transparent;
      height: 271px;
      padding-top: 20px;
      box-sizing: border-box;

      .list-group-item {
        border: none;
        background: transparent;
        padding: 20px 30px 20px 0px;
        box-sizing: border-box;
        font-size: 20px;
        font-weight: 400;
        color: #666666;
      }
    }
  }

  .dialog-right {
    overflow-y: scroll;

    .list-group {
      // width: 232px;
      background: transparent;
      height: 271px;
      padding-top: 30px;
      box-sizing: border-box;

      .list-group-item {
        border: none;
        background: transparent;
        padding: 14px 0px 14px 29px;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: 400;
        color: #333;
      }
    }

    .nav-img-box {
      margin-left: 30px;
      width: 300px;
      height: 190px;
      background: #ffffff;
      border: 1px solid #eaeaea;
      border-radius: 10px;
      box-sizing: border-box;

      img {
        // flex: 1;

        max-width: 200px;
        max-height: 150px;
      }
    }
  }
}

.big-nav {
  height: 80px;
  z-index: 1500 !important;
}

#navbarColor02 {
  background: #fff;
}

#navbarDropdown {
  text-align: center;
}

.navRight {
  // display: none !important;
}

.nav-big {
  width: 600px;
  // overflow-x: hidden;
}

//col-xl-
@media (min-width: 1200px) {
  .nav-big {
    width: 800px !important;
  }
}

@media (max-width: 991px) or (min-width: 1200px) {
  .navRight {
    display: flex !important;
  }
}

@media (max-width: 991px) {
  .nav-box {
    margin-right: 0 !important;
  }

  .line {
    display: none;
  }
}




.nav-box {
  width: 100%;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;

  /* IE10+ */
  &::-webkit-scrollbar {
    display: none;
    /* ChromeSafari */

    /* Chrome Safari */
    margin-right: 43px;
  }

  &::-webkit-scrollbar {
    width: 0.01rem;
    opacity: 0;
    display: none;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  &::-webkit-scrollbar-track {
    background-color: #fff;
    opacity: 0;
  }

  /*定义滑块 内阴影+圆角*/
  &::-webkit-scrollbar-thumb {
    width: 0.01rem;
    border-radius: 0.01rem;
    opacity: 0;
  }

}

.nav-item {
  position: relative;

  .navLine {
    width: 24px;
    height: 3px;
    background: #e58419;
    border-radius: 2px;
    // position: absolute;
    // left: 50%;
    // transform: translateX(-50%);
    // bottom: -0.5rem;
    display: none;
  }
}


.logo {
  width: 115px;
  height: 36px;
}

.line {
  margin: 0 23px;
  color: #cfcfcf;
}

.navActive {
  .nav-link {
    font-weight: 700 !important;
    color: #e58419 !important;
  }

  .navLine {
    display: block;
  }
}

.btn-box {
  // width: 116px;
  padding: 0 9px;
  box-sizing: border-box;
  height: 40px;
  background: #e58419;
  border: 1px solid #e58419;
  border-radius: 6px;
  line-height: 40px;
  font-weight: 400;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
}

.nav-link,
.dropdown-item {
  cursor: pointer;
  font-size: 16px;
}

.phone-num {
  font-size: 16px;
  margin-left: 4px;
  font-weight: 400;
  color: #333333;
}

.navbar-collapse {
  flex-grow: 0 !important;
}

.bg-color {
  background: #fff;
}



.dropdown-menu {
  cursor: pointer;
  min-width: 4rem !important;

  li {
    text-align: center;
  }
}
</style>
